<template>
  <component :is="faqData === undefined ? 'div' : 'b-card'">
    <!-- Alert: No item found -->
    <b-alert variant="danger" :show="faqData === undefined">
      <h4 class="alert-heading">Error fetching faq data</h4>
      <div class="alert-body">
        No faq found with this faq id. Check
        <b-link class="alert-link" :to="{ name: 'faq-list' }">
          Faq List
        </b-link>
        for other faqs.
      </div>
    </b-alert>

    <b-tabs v-if="faqData" class="tabs-primary" pills>
      <!-- Tab: Information -->
      <b-tab active>
        <template #title>
          <feather-icon icon="InfoIcon" size="16" class="mr-0 mr-sm-50" />
          <span class="d-none d-sm-inline">Faq</span>
        </template>
        <faqs-tab-information
          :faq-data="faqData"
          :faq-category-options="faqCategoryOptions"
          @refetch-data="refetchData"
          class="mt-2 pt-75"
        />
      </b-tab>
    </b-tabs>
  </component>
</template>

<script>
import { BTab, BTabs, BCard, BAlert, BLink } from "bootstrap-vue";
import { ref, onUnmounted, onMounted } from "@vue/composition-api";
import router from "@/router";
import store from "@/store";
import faqStoreModule from "../faqStoreModule";
import FaqsTabInformation from "./FaqsTabInformation.vue";

export default {
  components: {
    BTab,
    BTabs,
    BCard,
    BAlert,
    BLink,

    FaqsTabInformation,
  },
  setup() {
    const faqData = ref(null);
    const PRODUCT_CATEGORY_APP_STORE_MODULE_NAME = "app-faq";

    const faq = {
      id: 0,
      faq_category_id: null,
      alt: null,
      has_day_range: 0,
      start_date: null,
      end_date: null,
      is_active: 1,
      image_path: null,
    };

    // Register module
    if (!store.hasModule(PRODUCT_CATEGORY_APP_STORE_MODULE_NAME))
      store.registerModule(
        PRODUCT_CATEGORY_APP_STORE_MODULE_NAME,
        faqStoreModule
      );

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(PRODUCT_CATEGORY_APP_STORE_MODULE_NAME))
        store.unregisterModule(PRODUCT_CATEGORY_APP_STORE_MODULE_NAME);
    });

    const refetchData = () => {
      if (router.currentRoute.name == "faq-create") {
        faqData.value = faq;
      } else {
        store
          .dispatch("app-faq/fetchFaq", {
            id: router.currentRoute.params.id,
          })
          .then((response) => {
            console.log("get faq categpry response", response);
            faqData.value = response.data.faq;
          })
          .catch((error) => {
            console.log("error when fetching faq", error);
            if (error.response.status === 404) {
              faqData.value = undefined;
            }
          });
      }
    };

    onMounted(() => {
      refetchData();
      refetchOption();
    });

    const faqCategoryOptions = ref([]);

    const refetchOption = () => {
      store
        .dispatch("app/fetchOptionList", {
          faq_categories: true,
        })
        .then((response) => {
          faqCategoryOptions.value = response.data.faq_categories;
        });
    };

    return {
      faqData,
      refetchData,
      refetchOption,
      faqCategoryOptions,
    };
  },
};
</script>

<style></style>
