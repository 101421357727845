import axiosIns from "@/libs/axios";

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchFaqs(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axiosIns
          .get(`/faqs/list`, { params: queryParams })
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    fetchFaq(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axiosIns
          .get(`/faqs`, { params: queryParams })
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    addFaq(ctx, data) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post("/faqs", data)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    deleteFaq(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axiosIns
          .delete("/faqs", { params: queryParams })
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    updateStatus(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post(`/faqs/faq_status`, { id: queryParams })
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
  },
};
